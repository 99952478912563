// Copied from https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!Boolean(String.prototype.padStart)) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    // Floor if number or convert non-number to 0;
    let targetLen = targetLength >> 0;
    let padStr = String(Boolean(padString) ? padString : ' ');
    if (this.length > targetLen) {
      return String(this);
    }
    targetLen = targetLen - this.length;
    if (targetLen > padStr.length) {
      // Append to original to ensure we are longer than needed
      padStr += padStr.repeat(targetLen / (padString?.length ?? 1));
    }
    return padStr.slice(0, targetLen) + String(this);
  };
}
